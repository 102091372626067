import { render, staticRenderFns } from "./EsqueceuSenha.vue?vue&type=template&id=6cfcd720"
import script from "./EsqueceuSenha.vue?vue&type=script&lang=js"
export * from "./EsqueceuSenha.vue?vue&type=script&lang=js"
import style0 from "./EsqueceuSenha.vue?vue&type=style&index=0&id=6cfcd720&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports