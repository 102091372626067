<template>
	<v-row align="center" justify="center">
		<v-container fill-height>
			<v-row align="center" justify="center">
				<v-col cols="6">
					<v-card elevation="12" class="pa-8 rounded-xl" color="#F5F5F5">
						<v-card-title class="mb-4 pa-0">
							<AppLogo />
						</v-card-title>
						<v-card-text class="pa-0 mb-3">
							<p>Esqueceu sua senha? Entre em contato com a gente pelo telefone ou pelo chat:</p>
							<p>(64) 3631-4248 - Jataí - GO</p>
							<p>(64) 3602-7400 - Rio Verde - GO</p>
						</v-card-text>
						<v-card-actions>
							<v-row align="center">
								<v-col cols="6">
									<v-btn v-if="!loading" to="Signin" color="red" text large width="100%">
										Voltar
									</v-btn>
								</v-col>
								<v-col cols="6">
									<v-btn
										v-if="!loading"
										color="red"
										@click="entrarNoChat()"
										large
										width="100%"
										class="white--text"
									>
										Entrar no chat
									</v-btn>
								</v-col>
							</v-row>
						</v-card-actions>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</v-row>
</template>
<script>
import AppLogo from '@/components/templates/AppLogo'
import { mapActions } from 'vuex'

export default {
	components: { AppLogo },
	methods: {
		...mapActions(['showError']),
		entrarNoChat() {
			window.open('http://seculos.com.br/index.php/area-restrita')
		},
	},
}
</script>

<style>
.v-application p {
	margin-bottom: 5px;
}
</style>
