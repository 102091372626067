<template>
	<div>
		<DialogLoading :dialog="dialogLoading" :texto="texto"></DialogLoading>
		<v-row align="center" justify="center">
			<v-col md="11" xl="8">
				<CardPersonalizado titulo="Cotação" icone="mdi-clipboard-text-outline">
					<div class="cabeçalho">
						<v-row>
							<v-col md="2" sm="3" xs="6" cols="12" class="pa-1">
								Número
								<v-text-field
									solo
									:value="'Nº' + cabecalho.numeroCotacao"
									class="centered-input"
									placeholder="0"
									background-color="#FAFAFA"
									hide-details
									readonly
								></v-text-field>
							</v-col>
							<v-col md="8" sm="6" xs="6" cols="12" class="pa-1">
								Empresa
								<v-text-field
									solo
									:value="cabecalho.nomeEmpresa"
									placeholder="nome"
									background-color="#FAFAFA"
									hide-details
									readonly
								></v-text-field>
							</v-col>
							<v-col md="2" sm="3" xs="12" cols="12" class="pa-1">
								Data
								<v-text-field
									solo
									:value="converterData(cabecalho.data)"
									class="centered-input"
									placeholder="Data"
									background-color="#FAFAFA"
									hide-details
									readonly
								></v-text-field>
							</v-col>
							<v-col md="12" sm="12" xs="12" cols="12" class="pa-1 mb-2">
								Observação
								<v-text-field
									v-model="cabecalho.observacao"
									readonly
									solo
									background-color="#FAFAFA"
									hide-details
								></v-text-field>
							</v-col>
						</v-row>
					</div>
					<TabelaPersonalizada :headers="headers" :itens="itens" :search="search" :loading="loading">
						<template v-slot:top>
							<v-text-field
								v-model="search"
								:disabled="loading"
								class="pb-2"
								solo
								placeholder="Procure um item"
								background-color="#FAFAFA"
								hide-details
							></v-text-field>
						</template>
						<template v-slot:[`item.precoCotado`]="{ item }">
							<div class="precoCotado">
								<InputFormatado
									v-model="item.precoCotado"
									:desativado="confirmada || loading"
									@input="retornarPrecoFinal(item)"
									tamanhoInicial="7"
									:valor="formatarDecimal(item.precoCotado, true, 2)"
									cor="#FAFAFA"
								/>

								<!-- <v-text-field
									solo
									dense
									hide-details=""
									v-model="item.precoCotado"
									@input="retornarPrecoFinal(item)"
									v-mask="[
										'#,##',
										'##,##',
										'###,##',
										'####,##',
										'#####,##',
										'######,##',
										'#######,##',
									]"
									:disabled="confirmada"
									background-color="#FAFAFA"
									placeholder="0,00"
									elevation="9"
									autocomplete="false"
								/> -->
							</div>
						</template>
						<template v-slot:[`item.obsFornecedor`]="{ item }">
							<v-text-field
								solo
								v-model="item.observacao"
								placeholder="Observação"
								maxlength="200"
								dense
								background-color="#FAFAFA"
								hide-details
								:readonly="confirmada || loading"
							></v-text-field>
						</template>
						<!-- <template v-slot:[`item.fornecedorescotacao.acresDesc`]="{ item }">
							{{ formatarDecimal(item.fornecedorescotacao.acresDesc) }}%
						</template>
						<template v-slot:[`item.precoFinal`]="{ item }">
							R$ {{ formatarDecimal(item.precoFinal, true, 3) }}
						</template> -->
					</TabelaPersonalizada>
					<v-row justify="end">
						<v-col md="3" sm="6" xs="12" cols="12">
							<v-btn
								class="red--text"
								:disabled="confirmada || salvando || loading"
								width="100%"
								@click="salvarCotacao()"
								large
							>
								<v-icon left v-if="!salvando"> mdi-content-save-outline </v-icon>
								<v-progress-circular
									v-if="salvando"
									indeterminate
									color="primary"
									:size="20"
									class="mr-2"
								></v-progress-circular>
								Salvar
							</v-btn>
						</v-col>
						<v-col md="3" sm="6" xs="12" cols="12">
							<v-btn
								class="white--text"
								color="#CC2128"
								elevation="9"
								:disabled="confirmada || confirmando || loading"
								width="100%"
								@click="confirmarCotacao()"
								large
							>
								<v-icon left v-if="!confirmando"> mdi-check </v-icon>
								<v-progress-circular
									v-if="confirmando"
									indeterminate
									color="primary"
									:size="20"
									class="mr-2"
								></v-progress-circular>
								Confirmar
							</v-btn>
						</v-col>
					</v-row>
				</CardPersonalizado>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { mapActions } from 'vuex'
import { mapGetters } from 'vuex'
import TabelaPersonalizada from '../components/shared/tabelaPersonalizada.vue'
import CardPersonalizado from '../components/shared/cardPersonalizado.vue'
import InputFormatado from '../components/shared/InputFormatado.vue'
import DialogLoading from '../components/shared/DialogLoading.vue'

export default {
	data() {
		return {
			itens: [],
			total: 0,
			search: '',
			loading: false,
			confirmada: false,
			cabecalho: {},
			salvando: false,
			confirmando: false,
			dialogLoading: false,
			texto: 'Carregando... Aguarde!',
		}
	},
	components: {
		TabelaPersonalizada,
		CardPersonalizado,
		InputFormatado,
		DialogLoading,
	},
	computed: {
		...mapGetters(['idCotacao']),
		...mapGetters(['fornecedor']),
		headers: function () {
			if (this.cabecalho.observacaoEmpresa == 'O')
				return [
					{
						text: 'Código Produto',
						value: 'produtoscotacao.codigo',
						align: 'start',
					},
					{
						text: 'Descrição',
						value: 'produtoscotacao.descricao',
					},
					{
						text: 'Observação Empresa',
						value: 'produtoscotacao.observacao',
					},
					{
						text: 'Observação Fornecedor',
						value: 'obsFornecedor',
					},
					{
						text: 'Preço Cotado',
						value: 'precoCotado',
						align: 'end',
						width: '130px',
					},
				]
			else if (this.cabecalho.observacaoEmpresa == 'R')
				return [
					{
						text: 'Código Produto',
						value: 'produtoscotacao.codigo',
						align: 'start',
					},
					{
						text: 'Referência + Descrição',
						value: 'produtoscotacao.descricao',
					},
					{
						text: 'Observação Empresa',
						value: 'produtoscotacao.observacao',
					},
					{
						text: 'Observação Fornecedor',
						value: 'obsFornecedor',
					},
					{
						text: 'Preço Cotado',
						value: 'precoCotado',
						align: 'end',
						width: '130px',
					},
				]
			else
				return [
					{
						text: 'Código Produto',
						value: 'produtoscotacao.codigo',
						align: 'start',
					},
					{
						text: 'Descrição',
						value: 'produtoscotacao.descricao',
					},
					{
						text: 'Observação Fornecedor',
						value: 'obsFornecedor',
					},
					{
						text: 'Preço Cotado',
						value: 'precoCotado',
						align: 'end',
						width: '130px',
					},
					/*{
					text: '%Acrésc./Desc.',
					value: 'fornecedorescotacao.acresDesc',
					align: 'end',
				},
				{
					text: 'Preço Final',
					value: 'precoFinal',
					align: 'end',
				},*/
				]
		},
	},
	methods: {
		...mapActions({
			addIdCotacao: 'addIdCotacao',
		}),
		dialogLoadingClose() {
			this.dialogLoading = false
		},
		async loadCabecalho() {
			try {
				const response = await this.axios.get(`/cotacao/${this.idCotacao}`)
				this.cabecalho = response.data.cotacao
			} catch (error) {
				this.barrarCotacao()
			}
		},
		async loadItens() {
			try {
				this.loading = true
				const response = await this.axios.get(`/cotacao/${this.idCotacao}/produtosporfornecedor`, {
					params: {
						//search: this.search,
						id_fornecedor: this.fornecedor,
					},
				})
				this.itens = response.data.itens
				this.total = response.data.total
				this.loading = false

				if (!this.itens.length) this.barrarCotacao()
			} catch (error) {
				this.barrarCotacao()
			}
		},
		async confirmarCotacao() {
			this.confirmando = true
			this.loading = true
			this.texto = 'Confirmando...'
			this.dialogLoading = true
			try {
				const item = await this.axios.put(`/cotacao/${this.idCotacao}/produtosporfornecedor`, {
					itens: this.itens.filter((item) => item.precoAntigo != item.precoCotado || item.observacao != item.observacaoAntiga),
				})

				if (item.status == 204) {
					const confirmacao = await this.axios.put(`/cotacao/${this.idCotacao}/confirmado`, {
						id_fornecedor: this.fornecedor,
						confirmado: true,
					})
					if (confirmacao.status == 204) {
						this.addIdCotacao(0)
						this.$router.push('/minhascotacoes')
						this.$store.dispatch('showSuccess', 'Cotação finalizada com sucesso!')
					}
				} else this.$store.dispatch('showError', 'Erro ao confirmar, tente novamente!')
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.dialogLoading = false
				this.confirmando = false
				this.loading = false
			}
		},
		async salvarCotacao() {
			this.salvando = true
			this.loading = true
			this.texto = 'Salvando...'
			this.dialogLoading = true

			try {
				const item = await this.axios.put(`/cotacao/${this.idCotacao}/produtosporfornecedor`, {
					itens: this.itens.filter((item) => item.precoAntigo != item.precoCotado || item.observacao != item.observacaoAntiga),
				})

				if (item.status == 204) {
					this.addIdCotacao(0)
					this.$router.push('/minhascotacoes')
					this.$store.dispatch('showSuccess', 'Cotação salva com sucesso!')
				} else this.$store.dispatch('showError', 'Erro ao salvar, tente novamente!')
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.dialogLoading = false
				this.salvando = false
				this.loading = false
			}
		},
		verificarRota() {
			if (this.$route.params.id) this.addIdCotacao(this.$route.params.id)
		},
		barrarCotacao(mensagem) {
			if (!mensagem) mensagem = 'Cotação não encontrada!'
			this.$store.dispatch('showError', mensagem)
			this.$router.push('/minhascotacoes')
		},
		verificarCotacao() {
			const uuid = this.verificarUUID(this.idCotacao)

			if (!uuid) this.barrarCotacao()
			else {
				this.loadCabecalho()
				this.loadItens()
				this.verificarConfirmacao()
			}
		},
		async verificarConfirmacao() {
			try {
				const response = await this.axios.get('/getConfirmacao', {
					params: {
						cotacao_id: this.idCotacao,
						fornecedore_id: this.fornecedor,
					},
				})

				const fornecedor = response.data.fornecedor
				if (fornecedor) {
					if (fornecedor.confirmado) {
						this.barrarCotacao('Essa cotação já foi confirmada!')
					}
				}
			} catch (error) {
				this.$store.dispatch('showError', error)
			}
		},
		retornarPrecoFinal(item) {
			if (item.precoCotado) {
				const virgulas = item.precoCotado.search(',')
				if (virgulas) {
					let preco = item.precoCotado.replace(',', '.')
					preco = Number(preco)
					let acresDesc = Number(item.fornecedorescotacao.acresDesc)
					item.precoFinal = preco + (preco * acresDesc) / 100
				}
			} else item.precoFinal = ''
		},
	},
	mounted() {
		this.verificarRota(), this.verificarCotacao()
	},
}
</script>

<style>
.cabeçalho {
	padding: 8px 8px 0 8px;
	margin-bottom: 20px;
}
.col {
	padding: 4px;
}
.centered-input,
.centered-input input {
	text-align: center;
}
</style>