<template>
	<div>
		<v-navigation-drawer
			v-model="drawer"
			:mini-variant="miniVariant"
			:clipped="clipped"
			fixed
			app
			width="270"
			color="#F5F5F5"
		>
			<v-list flat>
				<v-list-item-group color="#CC2128">
					<v-list-item v-for="(item, i) in items" :key="i" :to="item.to" router exact>
						<v-list-item-action>
							<v-icon>{{ item.icon }}</v-icon>
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title v-text="item.title" />
						</v-list-item-content>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-navigation-drawer>
		<div>
			<v-app-bar
				height="80"
				class="rounded-b-xl pl-5 pr-5"
				elevation="9"
				color="#F5F5F5"
				:clipped-left="clipped"
				fixed
				rounded-b
				app
			>
				<v-container class="container-appbar">
					<v-row  no-gutters>
						<v-col cols="10">
							<v-app-bar-nav-icon @click.stop="drawer = !drawer" />
							<AppLogo />
						</v-col>
						<v-spacer />
						<v-col cols="2" class="d-flex justify-end align-center">
							<div class="d-none d-sm-inline-flex">{{ user.nomeFantasia }}</div>
							<v-menu left bottom offset-y>
								<template v-slot:activator="{ on }">
									<v-btn icon v-on="on" large>
										<v-icon>mdi-account-circle</v-icon>
									</v-btn>
								</template>
								<v-list>
									<v-list-item>
										<v-list-item-icon>
											<v-icon>mdi-account-outline</v-icon>
										</v-list-item-icon>
										<v-list-item-title>{{ user.nomeFantasia }}</v-list-item-title>
									</v-list-item>
									<v-divider />
									<v-list-item @click="alterarSenha()">
										<v-list-item-icon>
											<v-icon>mdi-cog-outline</v-icon>
										</v-list-item-icon>
										<v-list-item-title>Alterar senha</v-list-item-title>
									</v-list-item>
									<v-divider />
									<v-list-item @click="logout">
										<v-list-item-icon>
											<v-icon color="red">mdi-exit-to-app</v-icon>
										</v-list-item-icon>
										<v-list-item-content>Sair</v-list-item-content>
									</v-list-item>
								</v-list>
							</v-menu>
						</v-col>
					</v-row>
				</v-container>
			</v-app-bar>
		</div>
		<AlterarSenha :abrirDialog="abrir" tipo="fornecedor" @dialogClose="dialogClose" :id="user.id" />
	</div>
</template>

<script>
import { mapState } from 'vuex'
import AppLogo from './AppLogo.vue'
import AlterarSenha from '../shared/AlterarSenha.vue'

export default {
	data() {
		return {
			clipped: false,
			drawer: false,
			fixed: false,
			abrir: false,
			items: [
				{
					icon: 'mdi-clipboard-outline',
					title: 'Minhas Cotações',
					to: '/minhascotacoes',
				},
			],
			miniVariant: false,
			right: true,
			rightDrawer: false,
		}
	},
	components: {
		AppLogo,
		AlterarSenha,
	},
	computed: {
		...mapState(['user']),
	},
	methods: {
		verificaVisibilidade(admin) {
			if (admin && this.$store.getters.userAdmin) {
				return true
			}
			return !admin
		},
		logout() {
			this.$store.dispatch('logout')
			window.location.reload()
			//this.$router.push('/signin')
		},
		alterarSenha() {
			this.abrir = true
		},
		dialogClose() {
			this.abrir = false
		},
	},
}
</script>

<style>
@media only screen and (max-width: 1900px) {
  .container-appbar {
    max-width: none;
  }
}
</style>


