<template>
	<div class="text-center">
		<v-snackbar v-model="alert.visible" :color="alert.color">
			{{ alert.msg }}
			<template v-slot:action="{ attrs }">
			<v-btn text @click="alert.visible = false"  v-bind="attrs">Fechar</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>
<script>
import { mapState } from 'vuex'
export default {
	computed: mapState(['alert']),
}
</script>
