<template>
	<v-card>
		<v-data-table
			:headers="headers"
			:items="itens"
			:search="search"
			:loading= "loading"
			:options.sync="options"
			:server-items-length="total"
			fixed-header
			class="mb-4"
			:header-props="{ 'sort-by-text': 'Ordenar por' }"
			:footer-props="{
				'items-per-page-text': 'itens por página',
				'items-per-page-all-text': 'TODOS',
			}"
		>
			<template v-slot:no-data>
				<v-alert :value="true"> Nenhum item foi lançado </v-alert>
			</template>
			<template v-for="(slot, name) in $scopedSlots" v-slot:[name]="item">
				<slot :name="name" v-bind="item"></slot>
			</template>
			<template v-slot:[`footer.page-text`]="items">
				{{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
			</template>
			<slot></slot>
		</v-data-table>
	</v-card>
</template>

<script>
export default {
	props: ['search', 'headers', 'itens', 'loading', 'total'],
	data() {
        return {
            options: {}
        }
	},
	watch: {
		options: {
			handler() {
				this.$emit('setOptions', this.options)
			},
			deep: true,
		},
	}
	
}
</script>