<template>
	<!-- <v-text-field
		solo
		dense
		hide-details=""
		@input="onInputChange"
		v-model="content"
		@keypress="verificaLetra($event)"
		@keyup.188="virgula()"
		@keyup.110="virgula()"
		:maxlength="tamanho"
		:disabled="desativado"
		:background-color="cor"
		placeholder="0,00"
		elevation="9"
		autocomplete="false"
	/> -->
	<v-text-field
		solo
		dense
		hide-details=""
		@input="onInputChange"
		v-model="content"
		v-mask="['#,##', '##,##', '###,##', '####,##', '#####,##', '######,##', '#######,##']"
		:disabled="desativado"
		:background-color="cor"
		placeholder="0,00"
		elevation="9"
		autocomplete="false"
	/>
</template>

<script>
export default {
	props: ['valor', 'desativado', 'cor', 'tamanhoInicial', 'casasDecimais'],
	data() {
		return {
			content: this.valor,
			tamanho: this.tamanhoInicial,
			decimais: this.casasDecimais || 3,
		}
	},
	methods: {
		onInputChange() {
			this.$emit('input', this.content)
		},
		verificaLetra(e) {
			let char = String.fromCharCode(e.keyCode)
			if (/^[0-9]+$/.test(char)) return true
			else e.preventDefault()
		},
		virgula() {
			if (this.content) {
				const virgulas = this.content.search(',')

				if (this.content.length < this.tamanhoInicial + 1 && virgulas == -1) {
					this.content = this.content + ','
					this.$emit('input', this.content)
				}
			}
		},
	},
	watch: {
		content() {
			if (this.content) {
				const virgulas = this.content.indexOf(',')

				if (virgulas > 0) this.tamanho = virgulas + Number(this.decimais)

				if (virgulas < 0) {
					this.tamanho = this.tamanhoInicial
				}
			}
		},
	},
}
</script>