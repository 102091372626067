import Vue from 'vue'
import VueRouter from 'vue-router'

import MinhasCotacoes from '../views/CotacoesFornecedores.vue'
import SignIn from '../views/Signin.vue'
import ConfirmaCotacao from '../views/Confirmar.vue'
import EsqueceuSenha from '../views/EsqueceuSenha.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '*',
		component: MinhasCotacoes
	},
	{
		path: '/signin',
		name: 'Signin',
		component: SignIn
	},
	{
		path: '/esqueceusenha',
		name: 'Esqueceu Senha',
		component: EsqueceuSenha
	},
	{
		path: '/minhascotacoes',
		name: 'Minhas Cotações',
		component: MinhasCotacoes
	},
	{
		path: '/confirmacotacao/:id',
		name: 'Confirma Cotacao',
		component: ConfirmaCotacao
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})
export default router
