<template>
	<v-card class="pa-8 rounded-xl" :max-height="lagura" :width="altura">
		<v-card-title v-if="titulo" class="mb-2 pa-0 red--text text-h6 text-sm-h5">
			<v-icon color="red" large class="mr-2"> {{ icone }} </v-icon>
			{{ titulo }}
			<v-spacer></v-spacer>
			<v-icon color="red" @click="dialogClose()"> mdi-close </v-icon>
		</v-card-title>
		<div>
			<v-card-text>
				<slot></slot>
			</v-card-text>
		</div>
	</v-card>
</template>

<script>
export default {
	props: ['titulo', 'icone', 'lagura', 'altura'],
	methods: {
		dialogClose() {
			this.$emit('dialogClose')
		},
	},
}
</script>

