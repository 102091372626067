<template>
	<v-card elevation="9" class="pa-8 ma-4 rounded-xl" color="#F5F5F5" v-if="userAtivo"> 
		<v-card-title class="red--text mb-3 pa-0 text-h4 font-weight-medium">
			<v-icon v-if="icone" color="red" large class="mr-2"> {{ icone }} </v-icon> {{ titulo }}
		</v-card-title>
		<div>
			<slot></slot>
		</div>
	</v-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	props: ['titulo', 'icone', 'lagura', 'altura'],
	computed: {
		...mapGetters(['userAtivo'])
	}
}
</script>
