<template>
	<v-dialog ref="dialog" v-model="modal" :return-value.sync="date" persistent width="290px">
		<template v-slot:activator="{ on, attrs }">
			<v-text-field
				v-model="date"
				dense
				solo
				placeholder="Filtrar data"
				hide-details
				readonly
				v-bind="attrs"
				v-on="on"
				class="centered-input"
			>
				<template v-slot:prepend-inner>
					<v-icon> mdi-calendar-blank-outline </v-icon>
				</template>
			</v-text-field>
		</template>
		<v-date-picker v-model="dates" scrollable range locale="pt-br" color="red" no-title>
			<v-btn text color="red" @click="closeModal()"> Cancelar </v-btn>
			<v-spacer></v-spacer>
			<v-btn text color="red" @click="filtrar()"> Filtrar </v-btn>
		</v-date-picker>
	</v-dialog>
</template>
<script>
export default {
	data() {
		return {
			dates: [],
			date: '',
			modal: false,
		}
	},
	methods: {
		filtrar() {
			const d1 = new Date(this.dates[0])
			const d2 = new Date(this.dates[1])

			if (this.dates.length == 1) {
				this.date = this.converterData(this.dates[0])
			} else if (this.dates.length == 2) {
				if (d1.getTime() < d2.getTime())
					this.date = this.converterData(this.dates[0]) + ' - ' + this.converterData(this.dates[1])
				else {
					this.$store.dispatch('showError', 'A data final não pode ser inferior a data inicial')
					return ''
				}
			}
			this.$refs.dialog.save(this.date)
		},
		closeModal() {
			this.$refs.dialog.save((this.dates = []))
			this.modal = false
		},
	},
	watch: {
		date() {
			this.$emit('input', this.dates)
		},
	},
}
</script>