<template>
	<div>
		<v-row align="center" justify="center">
			<v-col md="11" xl="8">
				<CardPersonalizado titulo="Cotações" icone="mdi-clipboard-outline">
					<v-text-field
						v-model="search"
						solo
						placeholder="Procure uma cotação"
						elevation="9"
						background-color="#FAFAFA"
						hide-details
					></v-text-field>
					<v-row class="mt-1 mb-1">
						<v-col xl="2" lg="3" md="4" sm="3">
							<v-checkbox
								class="ml-2"
								:ripple="false"
								hide-details
								label="Confirmadas"
								@click=";(nao = false), loadItens()"
								color="#cc2128"
								v-model="confirmadas"
							></v-checkbox>
						</v-col>
						<v-col xl="2" lg="3" md="4" sm="3">
							<v-checkbox
								class="ml-2"
								:ripple="false"
								hide-details
								label="Não confirmadas"
								@click=";(confirmadas = false), loadItens()"
								color="#cc2128"
								v-model="nao"
							></v-checkbox>
						</v-col>
						<v-col xl="2" lg="3" md="4" sm="6" cols="12">
							<DataPicker v-model="datas" />
						</v-col>
					</v-row>
					<TabelaPersonalizada :headers="headers" :itens="cotacoes" :loading="loading" @setOptions="setOptions" :total="total">
						<template v-slot:[`item.cotacao.data`]="{ item }">
							{{ converterData(item.cotacao.data) }}
						</template>
						<template v-slot:[`item.cotacao.observacao`]="{ item }">
							<div class="observacao-cotacao">{{ item.cotacao.observacao }} </div>
						</template>
						<template v-slot:[`item.acoes`]="{ item }">
							<v-btn
								class="white--text mr-1"
								color="#CC2128"
								:disabled="item.confirmado"
								elevation="9"
								@click="confirmarCotacao(item.cotacao.uuid)"
							>
								<v-icon left> mdi-check </v-icon>
								Confirmar
							</v-btn>
							<v-btn
								class="white--text mr-1"
								color="#CC2128"
								elevation="9"
								@click="visualizarCotacao(item.cotacao.uuid)"
							>
								<v-icon left> mdi-eye-outline </v-icon>
								Visualizar
							</v-btn>
						</template>
					</TabelaPersonalizada>
				</CardPersonalizado>
			</v-col>
		</v-row>
		<DialogVisualizarItensCotacao :dialogVisualizarItens="this.visualizar" @dialogClose="dialogClose" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'

import DialogVisualizarItensCotacao from '../components/cotacoesFornecedores/dialogVisualizarItensCotacao.vue'
import CardPersonalizado from '../components/shared/cardPersonalizado.vue'
import TabelaPersonalizada from '../components/shared/tabelaPersonalizada.vue'
import DataPicker from '../components/shared/DataPicker.vue'

export default {
	data() {
		return {
			cotacoes: [],
			datas: [],
			search: '',
			visualizar: false,
			relatorio: false,
			total: 0,
			nao: false,
			confirmadas: false,
			loading: false,
			options: {},
			headers: [
				{
					text: 'Número Cotação',
					value: 'cotacao.numeroCotacao',
					width: '20px',
					sortable: false,
				},
				{
					text: 'Data',
					value: 'cotacao.data',
					align: 'center',
					sortable: false,
				},
				{
					text: 'Observação',
					value: 'cotacao.observacao',
					width: '300px',
					sortable: false,
				},
				{
					text: 'Empresa',
					value: 'cotacao.empresa.nomeFantasia',
					sortable: false,
				},
				{
					text: '',
					value: 'acoes',
					align: 'start',
					sortable: false,
				},
			],
		}
	},
	components: {
		DialogVisualizarItensCotacao,
		CardPersonalizado,
		DataPicker,
		TabelaPersonalizada,
	},
	computed: {
		...mapGetters(['idCotacao']),
		...mapGetters(['fornecedor']),
	},
	mounted() {
		this.loadItens()
	},
	watch: {
		datas() {
			this.loadItens()
		},
		search() {
			if (this.search.length > 0 || this.search.length == 0){ 
				this.loadItens()
				this.options.page = 1
			}
		},
	},
	methods: {
		...mapActions({
			addIdCotacao: 'addIdCotacao',
		}),
		async loadItens() {
			try {
				this.loading = true

				const { page, itemsPerPage } = this.options
				const response = await this.axios.get('/cotacao', {
					params: {
						search: this.search,
						page,
						limit: itemsPerPage,
						datas: this.datas,
						id_fornecedor: this.fornecedor,
						nao: this.nao,
						confirmadas: this.confirmadas,
					},
				})
				this.cotacoes = response.data.cotacoes
				this.total = response.data.total
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		setOptions(options){
			this.options = options
			this.loadItens()
		},
		confirmarCotacao(numero) {
			this.addIdCotacao(numero)
			//this.$router.push('/confirmaCotacao')
			this.$router.push({ name: 'Confirma Cotacao', params: { id: this.idCotacao } })
		},
		visualizarCotacao(numero) {
			this.addIdCotacao(numero)
			this.visualizar = true
		},
		async dialogClose() {
			this.visualizar = false
			this.relatorio = false
		},
		data(dates) {
			if (dates.length == 1) return this.converterData(dates[0])
			else if (dates.length == 2) return this.converterData(dates[0]) + ' - ' + this.converterData(dates[1])
		},

	},
}
</script>

<style scoped>
.observacao-cotacao{
	word-break: break-word
}
</style>