<template>
	<v-dialog v-model="dialog" persistent max-width="600">
		<dialogCardPersonalizado titulo="Ajuda" icone="mdi-help-circle-outline" @dialogClose="dialogClose">
		<p>Entre em contato com nossa equipe comercial ou suporte técnico.</p>
		<br/>
		<p><b>Rio Verde</b></p>
		<p>(64) 3602-7400</p>
		<p>(64) 9 9205-2800 (Plantão)</p>
		<br/>
		<p><b>Jataí</b></p>
		<p>(64) 3631-4248</p>
		<p>(64) 9 9610-8085 (Plantão)</p>
		<br/>
		<v-btn color="#CC2128" class="white--text mr-1" @click="entrarNoSite()"><v-icon>mdi-web</v-icon>site</v-btn>
		<v-btn color="#CC2128" class="white--text mr-1" @click="entrarNoChat()"><v-icon>mdi-chat-outline</v-icon>chat</v-btn>
	
		</dialogCardPersonalizado>
	</v-dialog>
</template>

<script>
import dialogCardPersonalizado from '../shared/DialogCardPersonalizado.vue'

export default {
	props: {
		abrirDialog: Boolean,
	},
	components: {
		dialogCardPersonalizado,
	},
	data() {
		return {
			
		}
	},
	computed: {
		dialog: {
			get() {
				return this.abrirDialog
			},
			set() {
				this.dialogClose()
			},
		},

	},
	methods: {
		dialogClose() {
			this.$emit('dialogClose')
		},
		entrarNoChat() {
			window.open('https://servidorseguro.mysuite1.com.br/client/chatan.php?h=095c68133537aca944bbef7b5a2bbb35&sl=sinf')
		},
		entrarNoSite() {
			window.open('https://seculos.com.br/')
		},
    }
}
</script>