<template>
	<v-dialog v-model="dialog" persistent max-width="1200">
		<dialogCardPersonalizado titulo="Itens Cotados" icone="mdi-archive-outline" @dialogClose="dialogClose">
			<v-text-field
				v-model="search"
				placeholder="Procurar produto"
				elevation="9"
				background-color="#FAFAFA"
				solo
				hide-details
			></v-text-field>
			<v-row class="mt-1 mb-1">
				<v-col>
					<v-checkbox
						class="ml-1"
						:ripple="false"
						hide-details
						label="Itens que obtiveram o menor preço"
						@click=";(pedido = false), loadProdutos()"
						color="#cc2128"
						v-model="menorPreco"
					></v-checkbox>
				</v-col>
				<v-col>
					<v-checkbox
						class="mb-4"
						:ripple="false"
						hide-details
						label="Itens já confirmados como pedido de compra"
						@click=";(menorPreco = false), loadProdutos()"
						color="#cc2128"
						v-model="pedido"
					></v-checkbox>
				</v-col>
			</v-row>

			<TabelaPersonalizada :headers="headers" :itens="produtos" :search="search" :loading="loading">
				<template v-slot:[`footer.page-text`]="items">
					{{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
				</template>
				<template v-slot:[`item.precoCotado`]="{ item }">
					R$ {{ formatarDecimal(item.precoCotado, false, 3) }}
				</template>
				<template v-slot:[`item.produtoscotacao.quantidade`]="{ item }">
					{{ formatarDecimal(item.produtoscotacao.quantidade) }}
				</template>
			</TabelaPersonalizada>
			<v-row class="mt-2 mr-1" justify="end">
				<downloadexcel
					class="v-btn v-btn--contained theme--light v-size--default primary"
					:data="JSON.parse(JSON.stringify(produtos))"
					:fields="fieldsExcel"
					:before-generate="retornarNomeArquivo"
					worksheet="Relatório"
					:header="nomeArquivo"
					:name="nomeArquivo + '.xls'"
				>
					SALVAR EXCEL
				</downloadexcel>
			</v-row>
		</dialogCardPersonalizado>
	</v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { mapGetters } from 'vuex'
import dialogCardPersonalizado from '../shared/DialogCardPersonalizado.vue'
import TabelaPersonalizada from '../shared/tabelaPersonalizada.vue'
import downloadexcel from 'vue-json-excel'

export default {
	props: {
		dialogVisualizarItens: Boolean,
		itens: [],
	},
	components: {
		dialogCardPersonalizado,
		TabelaPersonalizada,
		downloadexcel,
	},
	data() {
		return {
			nomeArquivo: '',
			search: '',
			menorPreco: false,
			pedido: false,
			total: 0,
			loading: false,
			options: {},
			produtos: [],
			headers: [
				{
					text: 'Código Produto',
					value: 'produtoscotacao.codigo',
					align: 'start',
				},
				{
					text: 'Descrição',
					value: 'produtoscotacao.descricao',
				},
				{
					text: 'Quantidade Cotada',
					value: 'produtoscotacao.quantidade',
					align: 'end',
				},
				{
					text: 'Observação',
					value: 'observacao',
					align: 'start',
				},
				{
					text: 'Preço Cotado',
					value: 'precoCotado',
					align: 'end',
				},
			],
			fieldsExcel: {
				'CÓDIGO PRODUTO': 'produtoscotacao.codigo',
				'DESCRIÇÃO': 'produtoscotacao.descricao',
				'QUANTIDADE COTADA': {
					field: 'produtoscotacao.quantidade',
					callback: (value) => {
						return this.formatarDecimal(value, false, 3)
					},
				},
				'OBSERVAÇÃO': 'observacao',
				'PREÇO COTADO': {
					field: 'precoCotado',
					callback: (value) => {
						return this.formatarDecimal(value, false, 3)
					},
				},
				'CÓDIGO': 'produtoscotacao.codigoOriginal',
				'CÓDIGO 1': 'produtoscotacao.codigo1',
				'CÓDIGO 2': 'produtoscotacao.codigo2',
				'CÓDIGO 3': 'produtoscotacao.codigo3',
			},
		}
	},
	computed: {
		dialog: {
			get() {
				this.loadProdutos()
				return this.dialogVisualizarItens
			},
			set() {
				this.dialogClose()
			},
		},
		...mapGetters(['idCotacao']),
		...mapGetters(['fornecedor']),
	},
	methods: {
		...mapActions({
			addIdCotacao: 'addIdCotacao',
		}),
		dialogClose() {
			this.produtos = []
			this.search = '',
			this.menorPreco = false,
			this.pedido = false,
			this.loadProdutos()
			this.$emit('dialogClose')
		},
		async loadProdutos() {
			try {
				if (this.idCotacao) {
					this.loading = true
					const response = await this.axios.get(`/cotacao/${this.idCotacao}/produtosporfornecedor/`, {
						params: {
							search: this.search,
							id_fornecedor: this.fornecedor,
							menor_preco: this.menorPreco,
							pedido: this.pedido,
						},
					})
					this.produtos = response.data.itens
					this.total = response.data.total
				}
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
		async retornarNomeArquivo() {
			try {
				this.loading = true

				let tipo = ''
				const response = await this.axios.get(`/cotacao/${this.idCotacao}`)
				if (this.menorPreco) tipo = 'ITENS QUE OBTIVERAM MENOR PREÇO'
				else if (this.pedido) tipo = 'ITENS JÁ CONFIRMADOS COMO PEDIDO DE COMPRA'
				else tipo = 'PRODUTOS COTADOS'

				this.nomeArquivo = `${response.data.cotacao.empresa.nomeFantasia} - COTAÇÃO Nº ${response.data.cotacao.numeroCotacao} - ${tipo}`
			} catch (error) {
				this.$store.dispatch('showError', error)
			} finally {
				this.loading = false
			}
		},
	},
	mounted() {
		this.loadProdutos()
	},
}
</script>