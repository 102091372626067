<template>
	<v-main>
	<v-btn fab large color="primary" fixed right bottom @click="dialogAjudaOpen">
        <v-icon dark>mdi-help-circle-outline</v-icon>
    </v-btn>
		<v-container :class="!user ? 'fill-height' : ''" fluid>
			<v-fade-transition mode="out-in">
				<router-view />
			</v-fade-transition>
		</v-container>
		<Ajuda :abrirDialog="this.ajuda" @dialogClose="dialogAjudaClose" />
	</v-main>
</template>
<script>
import { mapState } from 'vuex'
import Ajuda from '../ajuda/Ajuda.vue'

export default {
	metaInfo() {
		return {
			title: 'SIC Cotação',
		}
	},
	data(){
		return{
			ajuda: false
		}
	},
	computed: {
		...mapState(['user']),
	},
	components: { Ajuda },
	methods:{
		dialogAjudaOpen(){
			this.ajuda = true
		},
		dialogAjudaClose(){
			this.ajuda = false
		}
	}
}
</script>
