import Vue from 'vue'
import Vuex from 'vuex'

import * as types from './types'

Vue.use(Vuex)

const store = () => {
	return new Vuex.Store({
		state: {
			idCotacao: 0,
			redirect: '',
			alert: {},
			loginExpirado: false,
			user: null,
			userKey: '__user',
			fornecedor: ''
		},
		getters: {
			idCotacao: state => {
				return state.idCotacao
			},
			userAtivo: state => {
				return state.user !== null && state.user.ativo === true
			},
			fornecedor: state => {
				return state.fornecedor
			},
			redirect: state => {
				return state.redirect
			},
		},
		mutations: {
			[types.ADD_ID_COTACAO](state, idCotacao) {
				state.idCotacao = idCotacao
			},
			[types.ADD_REDIRECT](state, redirect) {
				state.redirect = redirect
			},
			setAlert(state, alert) {
				state.alert = alert
			},
			setUser(state, user) {
				state.user = user
			},
			setFornecedor(state, fornecedor) {
				state.fornecedor = fornecedor
			},
			setRedirect(state, redirect) {
				state.redirect = redirect
			},
			setLoginExpirado(state, loginExpirado) {
				state.loginExpirado = loginExpirado
			},
		},
		actions: {
			addIdCotacao({ commit }, idCotacao) {
				commit(types.ADD_ID_COTACAO, idCotacao)
			},
			showError({ commit }, error) {
				let msg = ''
				if (typeof error === 'string') msg += error
				else if (error.response.data.detalhes) {
					msg += error.response.data.detalhes
				} else if (error.response.data.msg) {
					msg += error.response.data.msg
				} else {
					msg += error.message
				}
				commit('setAlert', { color: 'error', msg, visible: true })

				if(msg == 'Sessão expirada! Faça o login e tente novamente!'  ) commit('setLoginExpirado', true)
			},
			showSuccess({ commit }, msg) {
				commit('setAlert', { color: 'success', msg, visible: true })
			},
			login({ commit, state }, user) {
				commit('setUser', user)
				commit('setFornecedor', user.id)

				localStorage.setItem(state.userKey, JSON.stringify(user))


				Vue.axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`
			},
			definirFornecedor({ commit, state }, fornecedor) {
				commit('setFornecedor', fornecedor)
				localStorage.setItem(state.fornecedor, fornecedor)
			},
			logout({ commit, state }) {
				commit('setUser', null)
				localStorage.removeItem(state.userKey)
			},
			addRedirect({ commit, state }, redirect) {
				commit('setRedirect', redirect)
				localStorage.setItem(state.redirect, redirect)
			},
			closeLoginExpirado({ commit }){
				commit('setLoginExpirado', false)
			}
		}
	})
}
export default store
