import Vue from 'vue'

var mixin = {
	data: function () {
		return {
			moment_format: 'DD/MM/YYYY HH:mm'
		}
	},
	methods: {
		converterData(date, separador, formato) {
			if (!date) return ''
			if (!separador) separador = '/'
			const [year, month, day] = date.split('-')

			if (formato == 'yyyy-MM-dd') return year + separador + month + separador + day.substring(0, 2)
			else return day.substring(0, 2) + separador + month + separador + year
		},
		formatarDecimal(valor, emBranco, decimal) {
			if ((!valor || valor == 0) && emBranco) return ''

			return parseFloat(valor.replace(',', '.'))
				.toFixed(decimal || 2)
				.replace('.', ',')
		},
		exibirAcrescimo(valor) {
			if (valor > 0) return valor
			else return 0
		},
		exibirDesconto(valor) {
			if (valor < 0) return valor
			else return 0
		},
		verificarUUID(uuid) {
			let s = '' + uuid

			s = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$')

			if (s === null) {
				return false
			}
			return true
		},
	}
}

Vue.mixin(mixin)
