<template>
	<span @click="home">
		<img src="@/assets/logo.png" class="img-logo" height="34" width="34" />
		<span class="title ml-3 mr-5">
			SIC&nbsp;
			<span class="font-weight-light">Cotação</span>
		</span>
	</span>
</template>

<script>
export default {
	methods:{
		home(){
			this.$router.push({ name: 'Minhas Cotações' })
		}
	}
}
</script>

<style scoped>
.img-logo {
	vertical-align: middle;
}
</style>
