import Vue from 'vue'
import App from './App.vue'

import './mixin/global'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins/axios'
import mask from './plugins/vue-the-mask'
import router from './router'

Vue.config.productionTip = false

new Vue({
	mask,
	vuetify,
	router,
	store,
	render: h => h(App)
}).$mount('#app')
