<template>
	<v-app id="app">
		<AppAlert />
		<LoginExpirado/>

		<AppBar v-if="userAtivo && !loading" />

		<AppContent v-if="!loading" />

		<AppLoading v-if="loading" />
	</v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
import AppBar from './components/templates/AppBar'
import AppContent from './components/templates/AppContent'
import AppLoading from './components/widgets/AppLoading'
import AppAlert from './components/templates/AppAlert'
import LoginExpirado from './components/templates/LoginExpirado.vue'

export default {
	components: {
		AppBar,
		AppContent,
		AppLoading,
		AppAlert,
		LoginExpirado
	},
	data: function () {
		return {
			loading: true,
			rotasPublicas: ['Signin', 'Signup'],
		}
	},
	methods: {
		...mapActions({
			addRedirect: 'addRedirect',
		}),
		async checkUser() {
			try {
				this.loading = true
				const localUserString = localStorage.getItem(this.$store.state.userKey)

				if (!localUserString) throw new Error('')

				const localUser = JSON.parse(localUserString)

				if (!localUser) throw new Error('')

				await this.axios.post('/checktokenFornecedor', { token: localUser.token })
				this.$store.dispatch('login', localUser)
			} catch (error) {
				this.publico()
			} finally {
				this.loading = false
			}
		},
		publico() {
			this.$store.dispatch('logout')

			this.addRedirect(this.$router.currentRoute.path)
			if (this.rotasPublicas.includes(this.$router.currentRoute.name)) return

			this.$router.push('/signin')
		},
	},
	computed: {
		...mapGetters(['userAtivo']),
		...mapGetters(['redirect']),
	},
	mounted() {
		this.checkUser()
	},
}
</script>

<style scoped>
#app {
	background-color: #c4c4c4;
}
</style>