<template>
	<v-row align="center" justify="center">
		<v-container fill-height>
			<v-row align="center" justify="center">
				<v-col cols="11" md="7" xl="5">
					<v-card elevation="12" class="pa-8 rounded-xl" color="#F5F5F5">
						<v-card-title class="mb-4 pa-0">
							<AppLogo />
						</v-card-title>
						<v-card-text class="pa-0">
							<!-- <v-row align="center" justify="center" class="pt-3">
								<v-progress-circular v-if="loading" color="primary" indeterminate />
							</v-row> -->
							<v-row align="center" v-if="loading" justify="center" class="pa-4">
								<v-progress-linear color="primary" indeterminate />
							</v-row>
							<v-form >
								E-mail
								<v-text-field
									v-model="email"
									placeholder="E-mail"
									solo
									type="email"
									background-color="#F5F5F5"
									hide-details=""
									class="pb-2"
								/>
								Senha
								<v-text-field
									background-color="#F5F5F5"
									solo
									placeholder="Senha"
									hide-details
									v-model="senha"
									@click:append="visualizar = !visualizar"
									:type="visualizar ? 'text' : 'password'"
									@keypress.enter="login"
								>
									<template v-slot:append>
										<v-icon color="red" @click="visualizar = !visualizar">
											{{ visualizar ? 'mdi-eye' : 'mdi-eye-off' }}
										</v-icon>
									</template>
								</v-text-field>
							</v-form>
						</v-card-text>
						<v-card-actions class="pb-2 pt-4 mb-2 pl-0 pr-0">
							<v-btn  :disabled="loading" class="white--text" color="#CC2128" large elevation="9" width="100%" @click="login">
								Entrar
							</v-btn>
						</v-card-actions>
						<v-row align="center">
							<v-col md="5" sm="5" xs="5" cols="12" class="pa-0">
								<!-- <v-btn v-if="!loading" to="Signup" color="#CC2128" text>Cadastre-se</v-btn> -->
							</v-col>
							<v-col md="7" sm="7" xs="7" cols="12" class="pa-0 text-right">
								<v-btn :disabled="loading" to="esqueceusenha" color="#CC2128" text min-width="0" class="pa-0 mr-3">
									Esqueceu a senha?
								</v-btn>
							</v-col>
						</v-row>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</v-row>
</template>
<script>
import AppLogo from '@/components/templates/AppLogo'
import { mapActions } from 'vuex'
import { mapGetters } from 'vuex'

export default {
	components: { AppLogo },
	data: function () {
		return {
			email: '',
			senha: '',
			visualizar: false,
			loading: false,
		}
	},
	computed: {
		...mapGetters(['redirect']),
	},
	methods: {
		...mapActions(['showError']),
		...mapActions({ addRedirect: 'addRedirect' }),
		async login() {
			try {
				this.loading = true
				if (!this.email || this.email === '') {
					throw 'Email inválido!'
				}
				if (!this.senha || this.senha === '') {
					throw 'Senha inválida!'
				}

				const response = await this.axios.post('/login', { email: this.email, senha: this.senha })

				if (response.data.ativo === false) {
					throw new 'Usuário inativo!'()
				}

				this.$store.dispatch('login', response.data)

				if (this.redirect && this.redirect != '/' && this.redirect != '/signin') {
					this.$router.push(this.redirect)
					this.addRedirect('')
				} else {
					this.$router.push('/minhascotacoes')
				}
			} catch (error) {
				this.showError('Usuário e/ou senha inválidos!')
				this.loading = false
			} finally {
				this.loading = false
			}
		},
		checkUser() {
			if (this.$store.getters.userAtivo) return this.$router.push('/')
		},
	},
	mounted() {
		this.checkUser()
	},
}
</script>
