<template>
	<v-dialog v-model="dialogLoading" persistent max-width="300">
		<v-card class="pa-8 rounded-xl">
			<v-card-title v-if="texto" class="pa-0 pt-5 pb-5 red--text text-h5">
				<v-row class="mb-2 ml-1">
					{{ texto }}
				</v-row>

				<v-progress-linear color="primary" indeterminate />
			</v-card-title>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
		texto: String,
		dialog: Boolean,
	},
	methods: {
		dialogClose() {
			this.$emit('dialogClose')
		},
	},
	computed: {
		dialogLoading: {
			get() {
				return this.dialog
			},
			set() {
				this.dialogClose()
			},
		},
	},
}
</script>

